<template>
	<div class="page-wrapper">
		<b-container fluid>
			<bo-page-title>
				<div class="ml-2">
					<router-link class="btn m-l-15 router-link-exact-active router-link-active btn-success"
						to="add-broker-forex">
						<i class="fas fa-plus mr-2"></i>
						Add New
					</router-link>
				</div>
			</bo-page-title>
			<b-tabs>
				<b-tab title="All Posts" id="article" active>
					<bo-card>
						<b-tabs pills card id="compare_info">
							<b-tab title="Information" active>
								<b-card-text>
									<b-row>
										<b-col md="9">
											<div class="wrap_information_compare">
												<h3>Apa itu Broker Forex</h3>
												<p>Ketatnya persaingan di antara pelaku bisnis perbankan, membuat setiap
													bank selalu berusaha untuk memberikan layanan terbaik bagi para
													nasabahnya. Beragam produk diluncurkan untuk memenuhi kebutuhan
													tersebut, salah satunya adalah kartu kredit... <a href="#"
														v-b-modal.modal-1>Read
														Details</a></p>
												<a href="#" v-b-modal.modal-1 class="cta_edit_info"><i
														class="ti-marker-alt"></i></a>
												<b-modal size="lg" id="modal-1" title="Add New Tab">
													<b-row>
														<b-col lg="12">
															<b-form-group label-for="catName">
																<label>Tab Name<span
																		class="text-danger mr5">*</span></label>
																<b-form-input id="catName"
																	placeholder="e.g Information" />
															</b-form-group>
														</b-col>

														<b-col lg="12">
															<b-form-group label-for="articleContent">
																<template #label>Content <span
																		class="text-danger mr5">*</span></template>
																<ckeditor :value="articleRow.content" />
															</b-form-group>
														</b-col>
													</b-row>
													<template #modal-footer>
														<div class="w-100">
															<b-button variant="success" class="float-right">
																Save
															</b-button>
															<b-button variant="default" class="float-right">
																Cancel
															</b-button>
														</div>
													</template>
												</b-modal>
											</div>
										</b-col>
									</b-row>
								</b-card-text>
							</b-tab>
							<b-tab title="+ Add New">
							</b-tab>
						</b-tabs>
					</bo-card>
					<b-card-header>
						<b-row>
							<b-col lg="12">
								<div class="wrap_sts_total">
									<ul>
										<li><a class="active_filter" href="">All <span>(557)</span></a> </li>
										<li><a href="">Published <span>(515)</span></a> </li>
										<li><a href="">Schedule <span>(557)</span></a> </li>
										<li><a href="">Draft <span>(557)</span></a> </li>
									</ul>
								</div>
							</b-col>
						</b-row>
					</b-card-header>
					<b-card-header>
						<b-row>
							<b-col md="auto">
								<b-button-group>
									<b-button v-b-tooltip.hover title="Delete"><i class="ti-trash"></i></b-button>
								</b-button-group>
							</b-col>
							<b-col md="auto">
								<b-button-group>
									<b-button @click="viewMode = 'list'" v-b-tooltip.hover title="List Mode"
										:variant="viewMode == 'list' ? 'success' : ''">
										<i class="fas fa-list"></i>
									</b-button>
									<b-button @click="viewMode = 'grid'" v-b-tooltip.hover title="Grid Mode"
										:variant="viewMode == 'grid' ? 'success' : ''">
										<i class="fas fa-th"></i>
									</b-button>
								</b-button-group>
							</b-col>
							<b-col md="auto">
								<v-date-picker v-model="date">
									<template v-slot="{ inputValue, inputEvents }">
										<div class="flex items-center">
											<input class="bg-white custom_inp px-2 py-1 rounded" :value="inputValue"
												v-on="inputEvents" />
										</div>
									</template>
								</v-date-picker>
							</b-col>
							<b-col md="3">
								<b-form-group class="mb-0">
									<v-select placeholer="Select Category" v-model="selected_category"
										:options="category" />
								</b-form-group>
							</b-col>
							<b-col md="auto">
								<bo-search />
							</b-col>
						</b-row>
					</b-card-header>
					<bo-card use-table v-if="viewMode == 'list'">
						<div class="table-responsive">
							<table class="table table-hover table-striped table-bordered">
								<thead>
									<tr>
										<th scope="col">
											<div class="custom-control custom-checkbox mr-sm-2">
												<input type="checkbox" class="custom-control-input" id="checkbox0"
													value="check">
												<label class="custom-control-label" for="checkbox0">
												</label>
											</div>
										</th>
										<th scope="col">Broker Forex Name</th>
										<th scope="col">Komisi</th>
										<th scope="col">Regulasi</th>
										<th scope="col">Status</th>
										<th scope="col">Actions</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<th>
											<div class="custom-control custom-checkbox mr-sm-2">
												<input type="checkbox" class="custom-control-input" id="checkbox0"
													value="check">
												<label class="custom-control-label" for="checkbox0">
												</label>
											</div>
										</th>
										<td>
											<div class="wrap_title_post">
												<h3><a href="">OctaFX</a>
												</h3>
												<ul>
													<li><i class="icon-user"></i> R Quiserto</li>
													<li><i class=" ti-calendar"></i> 2021/11/08 at 9:00 am</li>
												</ul>
											</div>
										</td>
										<td>Rp 10.000.000</td>
										<td>FCA</td>
										<td>
											<span class="badge badge-success">Published</span><br>
										</td>
										<td>
											<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
											<a href="#" class="icon_action"><i class="ti-trash"></i></a>
											<a href="#" class="icon_action"><i class="ti-eye"></i></a>
										</td>
									</tr>
									<tr>
										<th>
											<div class="custom-control custom-checkbox mr-sm-2">
												<input type="checkbox" class="custom-control-input" id="checkbox0"
													value="check">
												<label class="custom-control-label" for="checkbox0">
												</label>
											</div>
										</th>
										<td>
											<div class="wrap_title_post">
												<h3><a href="">Finance Freedom Success (FBS)</a>
												</h3>
												<ul>
													<li><i class="icon-user"></i> R Quiserto</li>
													<li><i class=" ti-calendar"></i> 2021/11/08 at 9:00 am</li>
												</ul>
											</div>
										</td>
										<td>Rp 10.000.000</td>
										<td>FCA</td>
										<td>
											<span class="badge badge-warning">Draft</span><br>
										</td>
										<td>
											<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
											<a href="#" class="icon_action"><i class="ti-trash"></i></a>
											<a href="#" class="icon_action"><i class="ti-eye"></i></a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<template #footer>
							<b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
								prev-text="Prev" next-text="Next" />
						</template>
					</bo-card>
					<bo-card v-else>
						<b-row class="gutter-2">
							<b-col lg="6">
								<article class="wrap_cc">
									<div class="row align-items-center">
										<div class="col-md-5">
											<div class="cc_info">
												<figure class="card_cover">
													<img src="https://via.placeholder.com/350x200" alt="" title="">
													<div data-v-85b626a6="" class="bulle_cta"><a href="/our-work-list/1"
															class="btn btn-icon btn-secondary rounded-pill"
															target="_self" data-v-85b626a6=""><i
																class="fas fa-pencil-alt"></i></a><button
															data-v-85b626a6="" type="button"
															class="btn btn-icon btn-danger rounded-pill"><i
																data-v-85b626a6=""
																class="far fa-trash-alt"></i></button></div>
												</figure>
											</div>
										</div>

										<div class="col-md-7">
											<div class="content_cc_short_desc">
												<h3><a href="detail-kartu-kredit.php">OctaFX</a></h3>
											</div>
											<div class="wrap_important_info">
												<div class="row">
													<div class="col-md-6">
														<p>Komisi</p>
														<h3>Rp 10.000.000</h3>
													</div>
													<div class="col-md-6">
														<p>Regulasi</p>
														<h3>FCA</h3>
													</div>
												</div>
											</div>
											<span data-v-85b626a6="" class="badge badge-success">Active</span>
										</div>
									</div>
								</article>
							</b-col>
							<b-col lg="6">
								<article class="wrap_cc">
									<div class="row align-items-center">
										<div class="col-md-5">
											<div class="cc_info">
												<figure class="card_cover">
													<img src="https://via.placeholder.com/350x200" alt="" title="">
													<div data-v-85b626a6="" class="bulle_cta"><a href="/our-work-list/1"
															class="btn btn-icon btn-secondary rounded-pill"
															target="_self" data-v-85b626a6=""><i
																class="fas fa-pencil-alt"></i></a><button
															data-v-85b626a6="" type="button"
															class="btn btn-icon btn-danger rounded-pill"><i
																data-v-85b626a6=""
																class="far fa-trash-alt"></i></button></div>
												</figure>
											</div>
										</div>

										<div class="col-md-7">
											<div class="content_cc_short_desc">
												<h3><a href="detail-kartu-kredit.php">Finance Freedom Success (FBS)</a>
												</h3>
											</div>
											<div class="wrap_important_info">
												<div class="row">
													<div class="col-md-6">
														<p>Komisi</p>
														<h3>Rp 10.000.000</h3>
													</div>
													<div class="col-md-6">
														<p>Regulasi</p>
														<h3>FCA</h3>
													</div>
												</div>
											</div>
											<span data-v-85b626a6="" class="badge badge-danger">In Active</span>
										</div>
									</div>
								</article>
							</b-col>
						</b-row>
					</bo-card>
				</b-tab>
				<b-tab title="SEO Settings">
					<b-card no-body>
						<b-form @submit.prevent="submitMethod">
							<b-card-body>
								<b-form-group label-for="homeMetaTitle">
									<template #label>
										Meta Title
										<b-badge variant="info" pill class="help-badge"
											v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
											<i class="fas fa-question"></i>
										</b-badge>
									</template>
									<b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoRow.title" />
								</b-form-group>
								<b-form-group label-for="homeMetaDesc">
									<template #label>
										Meta Description
										<b-badge variant="info" pill class="help-badge"
											v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
											<i class="fas fa-question"></i>
										</b-badge>
									</template>
									<b-form-textarea id="homeMetaDesc" placeholder="Meta Description"
										v-model="seoRow.description" />
								</b-form-group>
								<b-form-group label-for="homeMetaTags">
									<template #label>
										Meta Tags
										<b-badge variant="info" pill class="help-badge"
											v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
											<i class="fas fa-question"></i>
										</b-badge>
									</template>
									<b-form-tags id="homeMetaTags" placeholder="Type and press enter ..."
										remove-on-delete tag-variant="success" tag-class="text-white"
										v-model="seoRow.keywords" />
								</b-form-group>
							</b-card-body>
							<b-card-footer>
								<div class="text-right">
									<b-button type="submit" variant="success">Submit</b-button>
								</div>
							</b-card-footer>
						</b-form>
					</b-card>
				</b-tab>
				<b-tab title="Ads Placement">
					<b-card no-body>
						<b-card-body>
							<div class="row">
								<div class="col-md-12" id="ads_placement">
									<div class="mb_20">
										<b-form-group label-for="articleTitle">
											<template #label>Ads Device Type</template>
											<b-form-radio-group v-model="selected" :options="ads_device" class="mb-3"
												value-field="item" text-field="name" disabled-field="notEnabled">
											</b-form-radio-group>
										</b-form-group>
									</div>
									<b-tabs pills card vertical nav-wrapper-class="w-20" style="padding:0;"
										v-if="selected == 'A'">
										<b-tab title="Top Ads" active>
											<b-row>
												<b-col cols="12">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col"></th>
																	<th scope="col">Ads Name</th>
																	<th scope="col">Ads Size</th>
																	<th scope="col">Last Modified</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox switch id="activeAd"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		Ads #1
																	</td>
																	<td>728x90</td>
																	<td>
																		2021-11-03 13:52:00
																	</td>

																	<td>
																		<a href="#" class="icon_action"><i
																				class="ti-marker-alt"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
											<b-row>
												<b-col md="7">
													<b-form-group label-for="articleTitle">
														<template #label>Ads Name</template>
														<b-form-input id="articleTitle" v-model="articleRow.title"
															placeholder="#ads 1" />
													</b-form-group>
													<b-form-group label-for="articleTitle">
														<template #label>Ads Size<span
																class="text-danger mr5">*</span></template>
														<v-select placeholer="Select Category"
															v-model="selected_ads_size" :options="ads_size" />
													</b-form-group>
												</b-col>
												<b-col md="9">
													<b-form-group label-for="articleTitle">
														<template #label>Insert code<span
																class="text-danger mr5">*</span>
															<b-badge variant="info" pill class="help-badge"
																v-b-tooltip.hover.right="'Insert your adsense code'">
																<i class="fas fa-question"></i>
															</b-badge>
														</template>
														<b-form-textarea id="textarea" v-model="text"
															placeholder="Enter something..." rows="5" max-rows="5">
														</b-form-textarea>
													</b-form-group>
												</b-col>
												<b-col md="9">
													<b-form-group>
														<b-form-checkbox switch id="activeAd" size="lg">Activate Ad?
														</b-form-checkbox>
													</b-form-group>
												</b-col>
											</b-row>
										</b-tab>
										<b-tab title="Ads Sidebar #1">
											<b-row>
												<b-col cols="12">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col"></th>
																	<th scope="col">Ads Name</th>
																	<th scope="col">Ads Size</th>
																	<th scope="col">Last Modified</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox switch id="activeAd"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		Ads #1
																	</td>
																	<td>728x90</td>
																	<td>
																		2021-11-03 13:52:00
																	</td>

																	<td>
																		<a href="#" class="icon_action"><i
																				class="ti-marker-alt"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
											<b-row>
												<b-col md="7">
													<b-form-group label-for="articleTitle">
														<template #label>Ads Name</template>
														<b-form-input id="articleTitle" v-model="articleRow.title"
															placeholder="#ads 1" />
													</b-form-group>
													<b-form-group label-for="articleTitle">
														<template #label>Ads Size<span
																class="text-danger mr5">*</span></template>
														<v-select placeholer="Select Category"
															v-model="selected_ads_size" :options="ads_size" />
													</b-form-group>
												</b-col>
												<b-col md="9">
													<b-form-group label-for="articleTitle">
														<template #label>Insert code<span
																class="text-danger mr5">*</span>
															<b-badge variant="info" pill class="help-badge"
																v-b-tooltip.hover.right="'Insert your adsense code'">
																<i class="fas fa-question"></i>
															</b-badge>
														</template>
														<b-form-textarea id="textarea" v-model="text"
															placeholder="Enter something..." rows="5" max-rows="5">
														</b-form-textarea>
													</b-form-group>
												</b-col>
												<b-col cols="12">
													<b-form-group>
														<b-form-checkbox switch id="activeAd" size="lg">Activate Ad?
														</b-form-checkbox>
													</b-form-group>
												</b-col>
											</b-row>
										</b-tab>
										<b-tab title="Ads Sidebar #2">
											<b-row>
												<b-col cols="12">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col"></th>
																	<th scope="col">Ads Name</th>
																	<th scope="col">Ads Size</th>
																	<th scope="col">Last Modified</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox switch id="activeAd"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		Ads #1
																	</td>
																	<td>728x90</td>
																	<td>
																		2021-11-03 13:52:00
																	</td>

																	<td>
																		<a href="#" class="icon_action"><i
																				class="ti-marker-alt"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
											<b-row>
												<b-col md="7">
													<b-form-group label-for="articleTitle">
														<template #label>Ads Name</template>
														<b-form-input id="articleTitle" v-model="articleRow.title"
															placeholder="#ads 1" />
													</b-form-group>
													<b-form-group label-for="articleTitle">
														<template #label>Ads Size<span
																class="text-danger mr5">*</span></template>
														<v-select placeholer="Select Category"
															v-model="selected_ads_size" :options="ads_size" />
													</b-form-group>
												</b-col>
												<b-col md="9">
													<b-form-group label-for="articleTitle">
														<template #label>Insert code<span
																class="text-danger mr5">*</span>
															<b-badge variant="info" pill class="help-badge"
																v-b-tooltip.hover.right="'Insert your adsense code'">
																<i class="fas fa-question"></i>
															</b-badge>
														</template>
														<b-form-textarea id="textarea" v-model="text"
															placeholder="Enter something..." rows="5" max-rows="5">
														</b-form-textarea>
													</b-form-group>
												</b-col>
												<b-col cols="12">
													<b-form-group>
														<b-form-checkbox switch id="activeAd" size="lg">Activate Ad?
														</b-form-checkbox>
													</b-form-group>
												</b-col>
											</b-row>
										</b-tab>
									</b-tabs>
									<b-tabs v-else pills card vertical nav-wrapper-class="w-20">
										<b-tab title="Below Featured Article" active>
											<b-row>
												<b-col cols="12">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col"></th>
																	<th scope="col">Ads Name</th>
																	<th scope="col">Ads Size</th>
																	<th scope="col">Last Modified</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox switch id="activeAd"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		Ads #1
																	</td>
																	<td>728x90</td>
																	<td>
																		2021-11-03 13:52:00
																	</td>

																	<td>
																		<a href="#" class="icon_action"><i
																				class="ti-marker-alt"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
											<b-row>

												<b-col md="7">
													<b-form-group label-for="articleTitle">
														<template #label>Ads Name</template>
														<b-form-input id="articleTitle" v-model="articleRow.title"
															placeholder="#ads 1" />
													</b-form-group>
													<b-form-group label-for="articleTitle">
														<template #label>Ads Size<span
																class="text-danger mr5">*</span></template>
														<v-select placeholer="Select Category"
															v-model="selected_ads_size" :options="ads_size" />
													</b-form-group>
												</b-col>
												<b-col md="9">
													<b-form-group label-for="articleTitle">
														<template #label>Insert code<span
																class="text-danger mr5">*</span>
															<b-badge variant="info" pill class="help-badge"
																v-b-tooltip.hover.right="'Insert your adsense code'">
																<i class="fas fa-question"></i>
															</b-badge>
														</template>
														<b-form-textarea id="textarea" v-model="text"
															placeholder="Enter something..." rows="5" max-rows="5">
														</b-form-textarea>
													</b-form-group>
												</b-col>
												<b-col cols="12">
													<b-form-group>
														<b-form-checkbox switch id="activeAd" size="lg">Activate Ad?
														</b-form-checkbox>
													</b-form-group>
												</b-col>
											</b-row>
										</b-tab>
										<b-tab title="In Article List">
											<b-row>
												<b-col cols="12">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col"></th>
																	<th scope="col">Ads Name</th>
																	<th scope="col">Ads Size</th>
																	<th scope="col">Last Modified</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox switch id="activeAd"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>Ads #1</td>
																	<td>728x90</td>
																	<td>
																		2021-11-03 13:52:00
																	</td>
																	<td>
																		<a href="#" class="icon_action"><i
																				class="ti-marker-alt"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
											<b-row>
												<b-col md="7">
													<b-form-group label-for="articleTitle">
														<template #label>Ads Name</template>
														<b-form-input id="articleTitle" v-model="articleRow.title"
															placeholder="#ads 1" />
													</b-form-group>
													<b-form-group label-for="articleTitle">
														<template #label>Ads Size<span
																class="text-danger mr5">*</span></template>
														<v-select placeholer="Select Category"
															v-model="selected_ads_size" :options="ads_size" />
													</b-form-group>
												</b-col>
												<b-col md="9">
													<b-form-group label-for="articleTitle">
														<template #label>Insert code<span
																class="text-danger mr5">*</span>
															<b-badge variant="info" pill class="help-badge"
																v-b-tooltip.hover.right="'Insert your adsense code'">
																<i class="fas fa-question"></i>
															</b-badge>
														</template>
														<b-form-textarea id="textarea" v-model="text"
															placeholder="Enter something..." rows="5" max-rows="5">
														</b-form-textarea>
													</b-form-group>
												</b-col>
												<b-col cols="12">
													<b-form-group>
														<b-form-checkbox switch id="activeAd" size="lg">Activate Ad?
														</b-form-checkbox>
													</b-form-group>
												</b-col>
											</b-row>
										</b-tab>
									</b-tabs>
								</div>
							</div>
						</b-card-body>
						<b-card-footer>
							<div class="text-right">
								<b-button type="submit" variant="success">Submit</b-button>
							</div>
						</b-card-footer>
					</b-card>
				</b-tab>
			</b-tabs>
		</b-container>
	</div>
</template>
<script>
	import GlobalVue from '@/libs/Global.vue'
	export default {
		name: 'Article',
		extends: GlobalVue,
		components: {},

		data() {
			return {
				selected: 'A',
				ads_device: [{
						item: 'A',
						name: 'Desktop'
					},
					{
						item: 'B',
						name: 'Mobile'
					}
				],
				date: new Date(),
				days: [],
				featured: 'not_accepted',
				seoRow: {},
				articleRow: {},
				articleData: [],
				rows: 30,
				perPage: 1,
				currentPage: 5,
				selected_category: 'All Category',
				category: [
					'All Category',
					'Pinjaman Online',
					'Asuransi',
					'Bank Digital',
					'Investasi',
					'Rencana Keuangan',
				],
				selected_sort: 'Sort By',
				sort: [
					'Sort By',
					'Most Viewed',
					'Least Viewed',
					'Oldest',
					'Newest',
				],
				selected_ads_size: 'Select Ads Size',
				ads_size: [
					'Select Ads Size',
					'300x250',
					'336x280',
					'728x90',
					'300x600',
					'320x100',
					'200x50',
					'320x20',
				],
				viewMode: 'list'
			}
		},
		methods: {
			logData() {
				console.log('Button Pressed!')
			}
		},
	}
</script>